.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.footer {
  padding: 4px 16px ;
  font-size: 0.7rem;
}

.table {
  width: 100%
}

.row-mb {
  margin-bottom: 12px;
}

.card-graph > .ant-card-body {
  padding: 0 !important
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.table-bordered th, .table-bordered td {
  border:1px solid #eee;
  padding: 3px 5px
}

.ant-breadcrumb { margin-bottom: 14px !important}
.ant-form-item {
  margin-bottom: 8px !important;
}
.ant-tabs-nav { font-size: 14px}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab{ padding: 10px 0 !important}